// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-checkout-success-js": () => import("./../../../src/pages/checkout/success.js" /* webpackChunkName: "component---src-pages-checkout-success-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-returns-policy-js": () => import("./../../../src/templates/returns-policy.js" /* webpackChunkName: "component---src-templates-returns-policy-js" */),
  "component---src-templates-shop-collaborations-js": () => import("./../../../src/templates/shop/collaborations.js" /* webpackChunkName: "component---src-templates-shop-collaborations-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-stories-js": () => import("./../../../src/templates/stories.js" /* webpackChunkName: "component---src-templates-stories-js" */),
  "component---src-templates-stories-kibera-layout-js": () => import("./../../../src/templates/stories/kibera-layout.js" /* webpackChunkName: "component---src-templates-stories-kibera-layout-js" */),
  "component---src-templates-terms-of-service-js": () => import("./../../../src/templates/terms-of-service.js" /* webpackChunkName: "component---src-templates-terms-of-service-js" */)
}

